import {
  Avatar,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
} from '@nextui-org/react';
import Logo from './Logo';
import { Link, useLocation } from '@remix-run/react';
import { LayoutDashboard, LogOut, UserCircle } from 'lucide-react';

export default function NavBar({ user }) {
  //get the path of the current page in remix
  const path = useLocation().pathname;

  return (
    <Navbar maxWidth="full" className={`${path === '/analytics' && 'hidden'}`}>
      <NavbarBrand>
        <Link to="/">
          <Logo />
        </Link>
      </NavbarBrand>
      <NavbarContent className="hidden gap-4 sm:flex" justify="center">
        <NavbarItem>
          <Link color="foreground" to="/property">
            Properties
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link to="/agents" color="foreground">
            Agents
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link to="/stock-photos" color="foreground">
            Stock Photos
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link color="foreground" to="#">
            Contact
          </Link>
        </NavbarItem>
      </NavbarContent>
      <NavbarContent justify="end">
        <NavbarItem>
          <Link
            to={'/place-order'}
            className="relative z-0 box-border inline-flex h-10 min-w-20 select-none appearance-none items-center justify-center gap-2 overflow-hidden whitespace-nowrap rounded-medium bg-default/40 bg-lime-400 p-3 px-4 text-small font-normal text-white subpixel-antialiased shadow-lg shadow-lime-400 outline-none tap-highlight-transparent transition-transform-colors-opacity data-[focus-visible=true]:z-10 data-[pressed=true]:scale-[0.97] data-[hover=true]:opacity-hover data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-offset-2 data-[focus-visible=true]:outline-focus  motion-reduce:transition-none [&>svg]:max-w-[theme(spacing.8)]"
          >
            Place Order
          </Link>
        </NavbarItem>
        <NavbarItem>
          {user ? (
            <Dropdown placement="bottom-end">
              <DropdownTrigger>
                <Avatar
                  isBordered
                  as="button"
                  className="transition-transform"
                  src={user.profile_pic.url}
                  name={`${user.first_name} ${user.last_name}`}
                />
              </DropdownTrigger>
              <DropdownMenu aria-label="Link Actions" variant="flat">
                <DropdownItem className="h-14 cursor-default gap-2">
                  <p className="text-lg font-semibold">Signed in as</p>
                  <p className="text-sm font-semibold">{user.email}</p>
                </DropdownItem>
                <DropdownItem
                  href={`/dashboard/users/${user.type}/${user.id}/profile`}
                  key="profile"
                  startContent={<UserCircle />}
                >
                  My Profile
                </DropdownItem>
                <DropdownItem
                  href={`/dashboard`}
                  key="dashboard"
                  startContent={<LayoutDashboard className="h-5 w-5" />}
                >
                  Dashboard
                </DropdownItem>

                <DropdownItem
                  startContent={<LogOut />}
                  href={`/logout`}
                  key="logout"
                  color="danger"
                >
                  Log Out
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          ) : (
            <Link
              to="/login"
              className="relative z-0 box-border inline-flex h-10 min-w-20 select-none appearance-none items-center justify-center gap-2 overflow-hidden whitespace-nowrap rounded-medium  bg-lime-400/40 p-3 px-4 text-small font-normal text-black subpixel-antialiased shadow-lg shadow-lime-400/40 outline-none tap-highlight-transparent transition-transform-colors-opacity data-[focus-visible=true]:z-10 data-[pressed=true]:scale-[0.97] data-[hover=true]:opacity-hover data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-offset-2 data-[focus-visible=true]:outline-focus  motion-reduce:transition-none [&>svg]:max-w-[theme(spacing.8)]"
            >
              Login
            </Link>
          )}
        </NavbarItem>
      </NavbarContent>
    </Navbar>
  );
}
